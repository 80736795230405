import React from 'react'
import { Link } from 'react-router-dom'
import Search from '../components/search'

const Headers = () => {
  return (
    <>
      <div className="mobile-menu">
         <ul className="bottom-navbar-nav">
            <li className="bottom-nav-item active">
               <Link to="/" className="bottom-nav-link">
               <i className="fas fa-fw fa-home"></i>
               <span>Home</span>
               </Link>
            </li>
            <li className="bottom-nav-item">
               <Link to="/leagues" className="bottom-nav-link">
               <i className="fas fa-fw fa-layer-group"></i>
               <span>Leagues</span>
               </Link>
            </li>
            <li className="bottom-nav-item">
               <Link to="/tournaments" className="bottom-nav-link">
               <i className="fas fa-fw fa-trophy"></i>
               <span>Cups</span>
               </Link>
            </li>
            <li className="bottom-nav-item">
               <Link to="/international" className="bottom-nav-link">
               <i className="fas fa-fw fa-globe"></i>
               <span>Extras</span>
               </Link>
            </li>
            <li className="bottom-nav-item">
               <Link to="#!" className="bottom-nav-link">
               <i className="fas fa-fw fa-user-alt"></i>
               <span>Account</span>
               </Link>
            </li>
         </ul>
      </div>
      <nav className="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">
         &nbsp;&nbsp;
         <button className="btn btn-link btn-sm text-secondary order-1 order-sm-0" id="sidebarToggle">
         <i className="fas fa-bars"></i>
         </button> &nbsp;&nbsp;
         <Link className="navbar-brand mr-1" to="/"><img className="img-fluid" width="125px" alt="logo" src="./../assets/img/ftg_logo.png" /></Link>
         <Search />
         {/* <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
            <li className="nav-item mx-1">
               <Link className="nav-link" to="#!">
               DMCA
               </Link>
            </li>
            <li className="nav-item mx-1">
               <Link className="nav-link" to="#!">
               Community
               </Link>
            </li>
            <li className="nav-item mx-1">
               <Link className="nav-link" to="#!">
               Contact
               </Link>
            </li>
            <li className="nav-item dropdown no-arrow osahan-right-navbar-user">
            <Link className="nav-link dropdown-toggle user-dropdown-link" to="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <img alt="Avatar" src="./../assets/img/user.png" />
              
               </Link>
            </li>
         </ul> */}
      </nav>
    </>
  )
}

export default Headers
