import React from "react"
import ReactOwlCarousel from "react-owl-carousel"
import { favClubs } from "../util"
import { Link } from "react-router-dom"
import slugify from "slugify"

function Carousel() {
    const options = {
        responsive: {
            0:{items:1},
            600:{items:3, nav:false},
            1000:{items:4},
            1200:{items:8},
        },
        loop:true,
		lazyLoad:true,
		autoplay:true,
		autoplaySpeed:1000,
		autoplayTimeout:2000,
		autoplayHoverPause:true,
		nav:true,
		navText:["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
    }
  return (
    <ReactOwlCarousel className="owl-carousel owl-carousel-category" {...options}>
      {favClubs.map((fav, index) => (
         <div className="item" key={index}>
            <div className="category-item">
               <Link to={"/tag/"+slugify(fav.name, {lower:true})}>
                  <img className="img-fluid" src={"assets/img/clubs/"+fav.logo+".png"} alt={fav.name} />
                  <h6>{fav.name}</h6>
                  {/* <p>74,853 views</p> */}
               </Link>
            </div>
         </div>
      ))}
    </ReactOwlCarousel>
  )
}

export default Carousel
