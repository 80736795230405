// @ts-nocheck 
import { useParams } from "react-router-dom"
import Postcard from "../components/postcard"
import { deSlugify } from "../util"

function Category() {
    const {slug} = useParams()
  return (
    <div>
      <Postcard title={deSlugify(slug)} category={slug} />
    </div>
  )
}

export default Category
