import { configureStore } from '@reduxjs/toolkit'
import contentReducer from './../slices/content.slice'

export const store = configureStore({
    reducer: {
        contents:contentReducer
    }
})

export type AppDispatch  = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;