import { Helmet } from "react-helmet-async"
import Carousel from "../components/carousel"
import Postcard from '../components/postcard'


function Home() {
    
  return (
    <>
      <Helmet>
         <title>FullTimeGoal - Latest Football Highlights</title>
      </Helmet>
      <div className="top-category section-padding mb-4">
        <div className="row">
            <div className="col-md-12">
                {/* <div className="main-title">
                    <div className="btn-group float-right right-action">
                        <a href="#" className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                 <a className="dropdown-item" href="#"><i className="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                                 <a className="dropdown-item" href="#"><i className="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                                 <a className="dropdown-item" href="#"><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                              </div>
                           </div>
                           <h6>Popular</h6>
                        </div> */}
                     </div>
                     <div className="col-md-12">
                        <Carousel />
                     </div>
                  </div>
               </div>
            <hr style={{marginTop:0, marginBottom:0}} />
            <Postcard title="Latest Highlights" category="" />
    </>
  )
}

export default Home
