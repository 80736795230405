import { Link } from "react-router-dom"


function Nav() {
  return (
        <ul className="sidebar navbar-nav">
            
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/GB-ENG.svg" alt="england-flag" />
               <span>England</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/premier-league">Premier League</Link>
                  <Link className="dropdown-item" to="/category/champioship">Championship</Link>
                  <Link className="dropdown-item" to="/category/carabao-cup">Carabao Cup</Link>
                  <Link className="dropdown-item" to="/category/fa-emirate-cup">FA Emirate Cup</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/ES.svg" alt="england-flag" />
               <span>Spain</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/la-liga">La Liga</Link>
                  <Link className="dropdown-item" to="/category/copa-del-rey">Copa Del Rey</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/IT.svg" alt="england-flag" />
               <span>Italy</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/serie-a">Serie A</Link>
                  <Link className="dropdown-item" to="/category/coppa-italia">Coppa Italia</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/DE.svg" alt="england-flag" />
               <span>Germany</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/bundesliga">Bundesliga</Link>
                  <Link className="dropdown-item" to="/category/dfb-pokal">DFB Pokal</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/FR.svg" alt="england-flag" />
               <span>France</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/ligue-1">Ligue 1</Link>
                  <Link className="dropdown-item" to="/category/coupe-de-france">Coupe De France</Link>
                  <Link className="dropdown-item" to="/category/coupe-de-la-ligue">Coupe De La Ligue</Link>
               </div>
            </li>
            
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {/* <i className="fas fa-fw fa-list-alt"></i> */}
               <img src="./../assets/img/svg/s/EU.svg" alt="europe-flag" />
               <span>Europe</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/champions-league">Champions League</Link>
                  <Link className="dropdown-item" to="/category/europa-league">Europa League</Link>
                  <Link className="dropdown-item" to="/category/nations-league">Nations League</Link>
                  <Link className="dropdown-item" to="/category/super-cup">Super Cup</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <i className="fas fa-fw fa-globe"></i>
               <span>International</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/friendlies">Friendlies</Link>
                  <Link className="dropdown-item" to="/category/club-friendlies">Club Friendlies</Link>
                  <Link className="dropdown-item" to="/category/world-cup-qualifiers">Qualifiers</Link>
               </div>
            </li>
            <li className="nav-item dropdown">
               <Link className="nav-link dropdown-toggle" to="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <i className="fas fa-fw fa-plus"></i>
               <span>Extras</span>
               </Link>
               <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/category/mls">MLS</Link> 
                  <Link className="dropdown-item" to="/category/saudi-pro">Saudi Pro</Link>
                  <Link className="dropdown-item" to="/category/turkish-super-lig">Turkish Super Lig</Link>  
                  <Link className="dropdown-item" to="/category/eredivisie">Eredivisie</Link>   
               </div>
            </li>
            
            <li className="nav-item channel-sidebar-list">
               <h6>TOP CATEGORIES</h6>
               <ul>
                  <li>
                     <Link to="/category/premier-league">
                        <img className="img-fluid" alt src="./../assets/img/premier-league.jpeg" /> Premier League
                     </Link>
                  </li>
                  <li>
                     <Link to="/category/champions-league">
                        <img className="img-fluid" alt src="./../assets/img/champions-league.png" /> Champions League
                     </Link>
                  </li>
                  <li>
                     <Link to="/category/serie-a">
                        <img className="img-fluid" alt src="./../assets/img/serie-a.webp" /> Serie A
                     </Link>
                  </li>
                  <li>
                     <Link to="/category/bundesliga">
                        <img className="img-fluid" alt src="./../assets/img/bundesliga.webp" /> Bundesliga
                     </Link>
                  </li>
                  <li>
                     <Link to="/category/la-liga">
                        <img className="img-fluid" alt src="./../assets/img/la-liga.png" /> La Liga
                     </Link>
                  </li>
                  <li>
                     <Link to="/category/europa-league">
                        <img className="img-fluid" alt src="./../assets/img/europa.webp" /> Europa League
                     </Link>
                  </li>
               </ul>
            </li>
         </ul>
  )
}

export default Nav
