//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import { fetchContents } from '../slices/content.slice'
import { Link } from 'react-router-dom'
import { agoTime } from '../util'
import slugify from 'slugify'

interface Props {
   title:string,
   category:any
}

const Postcard:React.FC<Props> = ({title, category}) => {
   //fetch all post contents from server
   const dispatch = useAppDispatch()
   useEffect(() => {
      let isMounted = true
      const controller = new AbortController()
      if (isMounted){
         dispatch(fetchContents())
      }
      return () => {
         isMounted = false
         controller.abort()
      }
   },[dispatch])
   const {contents, isLoading} = useAppSelector(state => state.contents)
   let data = category ? contents.filter((content) => slugify(content.category, {lower:true}) === category) : contents

   //infinite scrolling controllers
   const [visible, setVisible] = useState(8)
   const loadMore = useCallback(() => {
      setVisible(prev => prev + 8)
   },[])
   const scrollHandler = useCallback(() => {
      const height = window.innerHeight + document.documentElement.scrollTop + 10 >= document.documentElement.offsetHeight
      if (height) loadMore()
   },[loadMore])
   useEffect(() => {
      window.addEventListener("scroll", scrollHandler);
      return () => window.removeEventListener("scroll", scrollHandler);
    }, [scrollHandler])


  return (
      <div className="video-block section-padding">
         <div className="row">
            <div className="col-md-12">
               <div className="main-title">
                  <div className="btn-group float-right right-action">
                     <a href="#" className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sort by <i className="fa fa-caret-down" aria-hidden="true"></i>
                     </a>
                     <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#"><i className="fas fa-fw fa-hourglass"></i> &nbsp; Most Recent</a>
                        <a className="dropdown-item" href="#"><i className="fas fa-fw fa-signal"></i> &nbsp; Most Viewed</a>
                        <a className="dropdown-item" href="#"><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                     </div>
                  </div>
                  <h6>{title}</h6>
               </div>
            </div>
            {isLoading ? <div><h6>Please wait..</h6></div> :
            <>{data.length > 0 ? data.slice(0, visible).map((item:any, index) => (
               <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                  <div className="video-card">
                     <div className="video-card-image">
                        <Link className="play-icon" to={"/watch/"+item.slug}><i className="fas fa-play-circle"></i></Link>
                        <Link to={"/watch/"+item.slug}><img className="img-fluid" src={item.platform === 'youtube' ? `https://img.youtube.com/vi/${item.video_id}/maxresdefault.jpg` : item.image} alt={item.slug} /></Link>
                        <div className="time">{item.duration}</div>
                     </div>
                     <div className="video-card-body">
                        <div className="video-title">
                           <Link to={"/watch/"+item.slug}>{item.title}</Link>
                        </div>
                        <div className="video-page text-success">
                           <Link to={"/category/"+slugify(item.category, {lower:true})}>{item.category}</Link>
                        </div>
                        <div className="video-view">
                           {item.views} views &nbsp;<i className="fas fa-calendar-alt"></i> {agoTime(item.createdAt)}
                        </div>
                     </div>
                  </div>
               </div>
            )) : <div> <h6>There seems to be no record yet.</h6></div>}</>}
         
         </div>
      </div>
  )
}

export default Postcard
