import React from 'react'
import Headers from '../commons/headers'
import Nav from '../commons/nav'
import Main from '../commons/main'
import { Link } from 'react-router-dom'
import ScrollTop from './ScrollTop'

function Layout() {
  return (
    <>
      <Headers />
      <div id="wrapper">
        <ScrollTop />
        <Nav />
        <Main />
      </div>
      <Link className="scroll-to-top rounded" to="#page-top">
         <i className="fas fa-angle-up"></i>
      </Link>
    </>
  )
}

export default Layout