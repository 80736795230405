import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Category from "../pages/Category";
import Watch from "../pages/Watch";
import Leagues from "../pages/Leagues";
import Tournaments from "../pages/Tournaments";
import International from "../pages/International";
import Tag from "../pages/Tag";
import Search from "../pages/Search";
import NotFound from "../pages/NotFound";
import { HelmetProvider } from "react-helmet-async";


function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
            <Route element={<Layout />} path="">
              <Route element={<Home />} path="/" />
              <Route element={<Category />} path="/category/:slug" />
              <Route element={<Watch />} path="/watch/:slug" />
              <Route element={<Leagues />} path="/leagues" />
              <Route element={<Tournaments />} path="/tournaments" />
              <Route element={<International />} path="/international" />
              <Route element={<Tag />} path="/tag/:club" />
              <Route element={<Search />} path="/search" />
              <Route element={<NotFound />} path="/404" />
            </Route>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;