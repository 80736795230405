//@ts-nocheck
// import { useState, useEffect, useRef } from "react";

// interface YTEmbedProps {
//     videoId: string
// }

// const YTEmbed: React.FC<YTEmbedProps> = ({videoId}) => {
//     const [isPlaying, setIsPlaying] = useState(false)
//     const [videoEnd, setVideoEnd] = useState(false)
//     const playerRef = useRef<YT.Player | null>(null)

//     useEffect(() => {
//         if (!window.YT){
//             const script = document.createElement("script")
//             script.src = "https://www.youtube.com/iframe_api"
//             script.async = true
//             script.onload = () => (window.YT ? onYouTubeIframeReady() : null)
//         }
//     },[])

//     const onYouTubeIframeReady = () => {
//         playerRef.current = new window.YT.Player(`youtube-player-${videoId}`, {
//             videoId:videoId,
//             playerVars: {
//                 autoplay:1,
//                 rel:0,
//                 modestbranding:1,
//                 controls:1,
//                 showInfo:0
//             },
//             events: {
//                 onStateChange: onPlayerStateChange
//             }
//         })
//     }

//     const onPlayerStateChange = (event: YT.onStateChangeEvent) => {
//         if (event.data === window.YT.PlayerState.ENDED) {
//             setVideoEnd(true)
//         } else if (event.data === window.YT.PlayerState.PLAYING) {
//             setVideoEnd(false)
//         }
//     }

//     const handlePlay = () => {
//         setIsPlaying(true)
//         setVideoEnd(false)
//     }

//     return (
//         <div style={{position:"relative", paddingBottom:"56.25%", height:"0", overflow:"hidden"}} >
//            {isPlaying ? (
//             <span id={`youtube-player-${videoId}`}></span>
//            ): videoEnd && <>
//                 <h3>Video has ended.</h3>
//                 {/* <img
//             src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
//             alt={ "YouTube Video"}
//             className="w-full h-full object-cover rounded-lg"
//           /> */}
//            </>} 
//         </div>
//     )

// }

// export default YTEmbed



import { useState, useEffect, useRef } from "react";

interface Prop {
    videoId: string;
    image: string;
}

const YouTubeIframe:React.FC<Prop> = ({videoId, image}) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const playerRef = useRef<YT.Player | null>(null)

    useEffect(() => {
        if (!window.YT) {
            const script = document.createElement("script");
            script.src = "https://www.youtube.com/iframe_api";
            script.async = true;
            script.onload = () => (window.YT ? createPlayer() : null)
            document.body.appendChild(script);
        } else {
            createPlayer()
        }
    },[])

    const createPlayer = () => {
        playerRef.current = new window.YT.Player(`yt-player-${videoId}`, {
            height:'',
            width: '100%',
            videoId:videoId,
            playerVars: {
                autoplay:1,
                controls:1,
                rel:0,
                showInfo:0
            },
            events:{}
        })
    } 

    const handlePlay = () => {
      setIsPlaying(true)
      playerRef.current?.playVideo()  
    }

    return (
        <div style={{position:"relative", paddingBottom:"56.25%", height:"0", overflow:"hidden"}}>
            <iframe
                style={{width:"100%", height:"100%", position:"absolute", left:"0px", top:"0px", overflow:"hidden", border:"none"}}
                src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&controls=1&showinfo=1&autoplay=1`}
                allow="picture-in-picture"
                allowFullScreen
            ></iframe>
            <div className="overlay"></div>
        </div>
       
        // <div style={{position:"relative", height:'360px', overflow:"hidden"}} >
        //     <div id={`yt-player-${videoId}`}></div>
        //     {/* <iframe
        //         style={{width:"100%", height:"100%", position:"absolute", left:"0px", top:"0px", overflow:"hidden", border:"none"}}
        //         src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&controls=1&showinfo=1&autoplay=1`}
        //         allow="picture-in-picture"
        //         allowFullScreen
        //     ></iframe> */}
        //     <div className="overlay"></div>
        //     <div className="clickable-area"></div>
        // </div>
     )
}

export default YouTubeIframe