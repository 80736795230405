import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import { fetchContents } from '../slices/content.slice'

function Tournaments() {
    const disptach = useAppDispatch()
    const {contents, isLoading} = useAppSelector(state => state.contents)

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        if (isMounted) {
            disptach(fetchContents())
        }
        return () => {
            isMounted = true
            controller.abort()
        }
    },[])

    const videoCount = (league:string) => {
        const data = contents.filter(item => item.category === league)
        return data.length
    } 


  return (
    <>
      <div className="video-block section-padding">
        <div className="row">
            <div className="col-md-12">
                <div className="main-title">
                    <h6>Tournaments</h6>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/champions-league.png" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Champions League</a>
                        </div>
                        <div className="channels-view">
                            {videoCount("Premier League")} videos
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/europa.webp" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Europa League</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/fa-cup.png" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Emirates FA Cup</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/copa-dey-rey.webp" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Copa Del Rey</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/coppa-italia.png" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Coppa Italia</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/dfb-pokal.avif" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">DFB Pokal</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/coupe-de-france.webp" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Coupe De France</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/nations-league.png" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Nations League</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <a href="#"><img className="img-fluid" src="./../assets/img/super-cup.png" alt="league" /></a>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <a href="#">Super Cup</a>
                        </div>
                        <div className="channels-view">
                            400 videos
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Tournaments
