import moment from 'moment'

export * from './constant'

export const agoTime = (date: Date | string): string => {
    moment.updateLocale('en', {
        relativeTime:{
            future: "in %s",
            past: "%s",
            s: "1 second ago",
            ss:"%s seconds ago",
            m:"1 minute ago",
            h:"1 hour ago",
            hh:"%d hours ago",
            d:"1 day ago",
            dd:"%d days ago",
            M:"1 month ago",
            MM:"%d months ago",
            y:"1 year ago",
            yy:"%d years ago"
        }
    })
    const now = moment()
    const inputDate = moment(date)
    return inputDate.from(now)
}

export const deSlugify = (slug:string) => {
    return slug
            .replace(/-/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase())
}