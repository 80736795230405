import React from 'react'

function International() {
  return (
    <>
        <div className="video-block section-padding">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-title">
                        <h6>International</h6>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="channels-card">
                        <div className="channels-card-image">
                            <a href="#"><img className="img-fluid" src="./../assets/img/champions-league.png" alt="league" /></a>
                        </div>
                        <div className="channels-card-body">
                            <div className="channels-title">
                                <a href="#">International Friendlies</a>
                            </div>
                            <div className="channels-view">
                                {/* {videoCount("Premier League")} videos */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="channels-card">
                        <div className="channels-card-image">
                            <a href="#"><img className="img-fluid" src="./../assets/img/champions-league.png" alt="league" /></a>
                        </div>
                        <div className="channels-card-body">
                            <div className="channels-title">
                                <a href="#">Club Friendlies</a>
                            </div>
                            <div className="channels-view">
                                {/* {videoCount("Premier League")} videos */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="channels-card">
                        <div className="channels-card-image">
                            <a href="#"><img className="img-fluid" src="./../assets/img/champions-league.png" alt="league" /></a>
                        </div>
                        <div className="channels-card-body">
                            <div className="channels-title">
                                <a href="#">World Cup Qualifiers</a>
                            </div>
                            <div className="channels-view">
                                {/* {videoCount("Premier League")} videos */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default International
