// @ts-nocheck 
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import { Link, useSearchParams } from 'react-router-dom'
import { agoTime, deSlugify } from '../util'
import { searchResult } from '../slices/content.slice'

function Search() {
    const [searchParams] = useSearchParams()
    const query = searchParams.get('q')
    const dispatch = useAppDispatch()
    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        
        if (isMounted)
            dispatch(searchResult(deSlugify(query)))
        return () => {
            isMounted = false
            controller.abort()
        }
    }, [query, dispatch])
    const {contents, isLoading} = useAppSelector(state => state.contents)
  return (
    <>
        <div className="video-block section-padding">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-title">
                        <h6>Search Result(s) for : {deSlugify(query)}</h6>
                    </div>
                </div>
                {<>
                    
                    {contents.length ? contents.map((item, index) => (
                        
                        <div className="col-xl-3 col-sm-6 mb-3" key={index}>
                            <div className="video-card">
                                <div className="video-card-image">
                                    <div className="video-card-image">
                                        <Link className="play-icon" to={"/watch/"+item.slug}><i className="fas fa-play-circle"></i></Link>
                                        <Link to={"/watch/"+item.slug}><img className="img-fluid" src={item.platform === 'youtube' ? `https://img.youtube.com/vi/${item.video_id}/maxresdefault.jpg` : item.image} alt={item.slug} /></Link>
                                        <div className="time">{item.duration}</div>
                                    </div>
                                    <div className="video-card-body">
                                        <div className="video-title">
                                        <Link to={"/watch/"+item.slug}>{item.title}</Link>
                                        </div>
                                        <div className="video-page text-success">
                                        <Link to={"/category/"+slugify(item.category, {lower:true})}>{item.category}</Link>
                                        </div>
                                        <div className="video-view">
                                        {item.views} views &nbsp;<i className="fas fa-calendar-alt"></i> {agoTime(item.createdAt)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <div><h6>No record found</h6></div>}
                </>}
                
            </div>
        </div>
    </>
  )
}

export default Search
