import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import { fetchContents } from '../slices/content.slice'
import { Link } from 'react-router-dom'

function Leagues() {
    const disptach = useAppDispatch()
    const {contents, isLoading} = useAppSelector(state => state.contents)

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        if (isMounted) {
            disptach(fetchContents())
        }
        console.log(contents)
        return () => {
            isMounted = true
            controller.abort()
        }
    },[])

    const videoCount = (league:string) => {
        const data = contents.filter(item => item.category === league)
        return data.length
    } 


  return (
    <>
      <div className="video-block section-padding">
        <div className="row">
            <div className="col-md-12">
                <div className="main-title">
                    <h6>Leagues</h6>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/premier-league"><img className="img-fluid" src="./../assets/img/premier-league.jpeg" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/premier-league">Premier League</Link>
                        </div>
                        <div className="channels-view">
                            {videoCount("Premier League")} videos
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/la-liga"><img className="img-fluid" src="./../assets/img/la-liga.png" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/la-liga">La Liga</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("La Liga")} videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/bundesliga"><img className="img-fluid" src="./../assets/img/bundesliga.webp" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/bundesliga">Bundesliga</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("Bundesliga")} videos
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/serie-a"><img className="img-fluid" src="./../assets/img/serie-a.webp" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/serie-a">Serie A</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("Serie A")} videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/ligue-1"><img className="img-fluid" src="./../assets/img/league-1.png" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/ligue-1">Ligue 1</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("Ligue 1")} videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/efl-championship"><img className="img-fluid" src="./../assets/img/efl.png" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/efl-championship">EFL Championship</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("EFL Championship")} videos
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <div className="channels-card-image">
                        <Link to="/category/eredivisie"><img className="img-fluid" src="./../assets/img/eredivisie.png" alt="league" /></Link>
                    </div>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <Link to="/category/eredivisie">Eredivisie</Link>
                        </div>
                        <div className="channels-view">
                        {videoCount("Eredivisie")} videos
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Leagues
