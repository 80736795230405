//@ts-nocheck
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import { fetchContents } from '../slices/content.slice'
import { DiscussionEmbed } from 'disqus-react'
import { getContent } from '../app/axios'
import { DailyMotionFrame, RuTubeFrame, YouTubeFrame } from '../components/frames'
import { Helmet } from 'react-helmet-async'
import { agoTime } from '../util'
import slugify from 'slugify'
import YouTubeIframe from '../components/youtube-embed'
//import YouTubePlayer from '../components/ytplayer'

function Watch() {
   const {slug} = useParams()
   // const navigate = useNavigate()
   const dispatch = useAppDispatch()
   useEffect(() => {
      if (slug) {
         getContent(slug)
      }
   },[slug])
   useEffect(() => {
      let isMounted = true
      const controller = new AbortController()
      if (isMounted) {
         dispatch(fetchContents())
      }
      return () => {
         isMounted = false
         controller.abort()
      }
   },[])
   const {contents} = useAppSelector(state => state.contents)
   const data = contents.find(item => item.slug === slug)
   // if (!data) navigate('/404')
   const related = contents.filter(item => item.category === data?.category && item.slug !== slug).slice(0,10)
  return (
    <>
      <Helmet>
         <title>FullTimeGoal - {data?.title || ''}</title>
         {/* og tags */} {/* create a base url for content sharing */}
         <meta property="og:type" content="website" />
         <meta property="og:url" content="http://www.fulltimegoal.com.s3-website-us-east-1.amazonaws.com/watch/aston-villa-vs-liverpool-or-highlight-or-premier-league-or-2425" />
         <meta property="og:title" content={data?.title} />
         <meta property="og:description" content={data?.title} />
         <meta property="og:image" content={data?.meta?.o || ''} />
         <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="630" />
      </Helmet>
        <div className="video-block section-padding">
            <div className="row">
                <div className="col-md-8">
                    <div className="single-video-left">
                        <div className="single-video">
                           {data?.platform === 'daily-motion' && <DailyMotionFrame data={data} />}
                           {data?.platform === 'youtube' && <YouTubeIframe videoId={data.video_id} image={data.image} />}
                           {data?.platform === 'rutube' && <RuTubeFrame data={data} />}
                        </div>
                        <div className="single-video-title box mb-2">
                            <h2><a href="#">{data?.title}</a></h2>
                            <p className="mb-0"><i className="fas fa-eye"></i> {data?.views} views</p>
                        </div>
                        
                        <div className="col-md-12">
                           <div className="adblock">
                              <div className="img">
                                 Google AdSense<br/>
                                 336 x 280
                              </div>
                           </div>
                        </div>
                       
                        <DiscussionEmbed
                           shortname='fulltimegoal-com'
                           config={
                              {
                                    identifier: data?.slug,
                                    title: data?.title,
                                    language: 'en' //e.g. for Traditional Chinese (Taiwan)
                              }
                           }
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-video-right">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="adblock">
                                    <div className="img">
                                       Google AdSense<br/>
                                       336 x 280
                                    </div>
                                </div>
                                <div className="main-title">
                                    <div className="btn-group float-right right-action">
                                       <a href="#" className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       Sort by <i className="fa fa-caret-down" aria-hidden="true"></i>
                                       </a>
                                       <div className="dropdown-menu dropdown-menu-right">
                                          <a className="dropdown-item" href="#"><i className="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                                          <a className="dropdown-item" href="#"><i className="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                                          <a className="dropdown-item" href="#"><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                                       </div>
                                    </div>
                                    <h6>Up Next</h6>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {related.length > 0 && related.flatMap((item, index) => (
                                    index % 5 === 4
                                    ? [<div className="video-card video-card-list" key={index}>
                                       <div className="video-card-image">
                                          <Link className="play-icon" to={"/watch/"+item.slug}><i className="fas fa-play-circle"></i></Link>
                                          <Link to={"/watch/"+item.slug}><img className="img-fluid" src={item.platform === 'youtube' ? `https://img.youtube.com/vi/${item.video_id}/maxresdefault.jpg` : item.image} alt={item.slug} /></Link>
                                          <div className="time">{item.duration}</div>
                                       </div>
                                       <div className="video-card-body">
                                          
                                          <div className="video-title">
                                             <Link to={"/watch/"+item.slug}>{item.title}</Link>
                                          </div>
                                          <div className="video-page text-success">
                                             {item.category}
                                          </div>
                                          <div className="video-view">
                                             {item.views} views &nbsp;<i className="fas fa-calendar-alt"></i> {agoTime(item.createdAt)}
                                          </div>
                                       </div>
                                    </div>, 
                                    <div className="adblock">
                                       <div className="img">
                                          Google AdSense<br/>
                                          336 x 280
                                       </div>
                                    </div>]
                                    :
                                    <div className="video-card video-card-list" key={index}>
                                       <div className="video-card-image">
                                          <Link className="play-icon" to={"/watch/"+item.slug}><i className="fas fa-play-circle"></i></Link>
                                          <Link to={"/watch/"+item.slug}><img className="img-fluid" src={item.platform === 'youtube' ? `https://img.youtube.com/vi/${item.video_id}/maxresdefault.jpg` : item.image}  alt={item.slug} /></Link>
                                          <div className="time">{item.duration}</div>
                                       </div>
                                       <div className="video-card-body">
                                          
                                          <div className="video-title">
                                             <Link to={"/watch/"+item.slug}>{item.title}</Link>
                                          </div>
                                          <div className="video-page text-success">
                                          <Link to={"/category/"+slugify(item.category, {lower:true})}>{item.category}</Link>
                                          </div>
                                          <div className="video-view">
                                             {item.views} views &nbsp;<i className="fas fa-calendar-alt"></i> {agoTime(item.createdAt)}
                                          </div>
                                       </div>
                                    </div>
                                ))}
               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Watch
