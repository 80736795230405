import { Link, Outlet } from 'react-router-dom'
import Footer from './footer'
import { MobileSearch } from '../components/search'

const Main = () => {
  return (
    <>
        <div id="content-wrapper">
            <div className="container-fluid pb-0" style={{minHeight:"100vh"}}>
                <div className="top-mobile-search">
                    <div className="row">
                        <div className="col-md-12">
                            <MobileSearch />
                        </div>
                    </div>
                </div>
                <Outlet />
            </div>
            <Footer />
        </div>
        <Link className="scroll-to-top rounded" to="#page-top">
            <i className="fas fa-angle-up"></i>
        </Link>
    </>
  )
}

export default Main
