import React, { useState } from 'react'
import slugify from 'slugify'
import { useNavigate } from 'react-router-dom'

function Search() {
    const navigate = useNavigate()
    const [q, setQ] = useState('')
    const handleSearch = async (e:any) => {
        e.preventDefault()
        if (q && q !== ''){
            navigate('/search?q='+slugify(q))
        } else 
            navigate('/')
    }
  return (
    <>
        <form className="d-none d-md-inline-block form-inline mx-auto my-md-0 osahan-navbar-search" onSubmit={handleSearch}>
            <div className="input-group">
               <input type="text" className="form-control" name="q" onChange={(e) => setQ(e.target.value)} placeholder="Search for..." />
               <div className="input-group-append">
                  <button className="btn btn-light" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
               </div>
            </div>
        </form>
    </>
  )
}

export const MobileSearch = () => {
    const navigate = useNavigate()
    const [q, setQ] = useState('')
    const handleSearch = async (e:any) => {
        e.preventDefault()
        if (q && q !== ''){
            navigate('/search?q='+slugify(q))
        } else 
            navigate('/')
    }
    return (
        <>
            <form className="mobile-search" onSubmit={handleSearch}>
                <div className="input-group">
                    <input type="text" placeholder="Search for..." name="q" onChange={(e) => setQ(e.target.value)} className="form-control" />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-dark"><i className="fas fa-search"></i></button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Search
