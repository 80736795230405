export const favClubs = [
    {
        name:"Real Madrid",
        logo:"madrid"
    },
    {
        name:"Manchester City",
        logo:"man-city"
    },
    {
        name:"Barcelona",
        logo:"barca"
    },
    {
        name:"Chelsea",
        logo:"chelsea"
    },
    {
        name:"Liverpool",
        logo:"liverpool"
    },
    {
        name:"Manchester United",
        logo:"man-united"
    },
    {
        name:"PSG",
        logo:"psg"
    },
    {
        name:"Bayern Munich",
        logo:"bayern"
    },
    {
        name:"Arsenal",
        logo:"arsenal"
    },
    {
        name:"AC Milan",
        logo:"ac-milan"
    }
]