import React from 'react'

function Footer() {
  return (
    <>
      <footer className="sticky-footer">
        <div className="container">
            <div className="row no-gutters">
                <div className="col-lg-6 col-sm-6">
                <p className="mt-1 mb-0">&copy; Copyright 2025 <strong className="text-dark">FullTimeGoal</strong>. <span className="mt-0 mb-0"> All Rights Reserved</span><br/>
                    
                </p>
                </div>
                <div className="col-lg-6 col-sm-6 text-right">
                <div className="app">
                    <span className="mr-4"><a href="#">DMCA</a></span>
                    <span className="mr-4"><a href="#">Privacy Policy</a></span>
                    <span className="mr-4"><a href="#">Contact Us</a></span>
                </div>
             </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
