import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'
import { Content } from '../interfaces/content.interface'
import { getContent, getContents, getSearch } from '../app/axios'

export interface InitialState {
    isLoading:boolean,
    contents:Content[],
    //content:Content,
    error:string
}

const initialState:InitialState = {
    isLoading:false,
    contents:[],
    //content:'',
    error:''
}

export const searchResult = createAsyncThunk('contents/searchResult', async (term:string) => {
    const response = await getSearch(term)
    return response.data
})

export const fetchContents = createAsyncThunk('contents/fetchContents', async () => {
    const response = await getContents()
    return response.data
})

export const fetchContent = createAsyncThunk('content/fetchContent', async (slug:string) => {
    const response = await getContent(slug)
    return response.data
})

const contentSlice = createSlice({
    name:'contents',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchContents.fulfilled, (state, action) => {
                state.isLoading = false
                const resp = action.payload
                if (resp.response && !resp.error){
                    state.contents = resp.response
                }
                 else 
                    state.error = 'Error conecting to database'
            })
            .addCase(fetchContents.rejected, (state) => {
                state.isLoading = false
                state.error = 'Server conection or network error'
            })
            .addCase(searchResult.pending, (state) => {
                state.isLoading = true
            })
            .addCase(searchResult.fulfilled, (state, action) => {
                state.isLoading = false
                const resp = action.payload
                if (resp.response && !resp.error){
                    state.contents = resp.response
                } else 
                    state.error = 'Error connecting to database'
            })
            .addCase(searchResult.rejected, (state) => {
                state.isLoading = false
                state.error = 'Error connecting to database'
            })
    }
})

export default contentSlice.reducer