// @ts-nocheck 
export const DailyMotionFrame = ({data}) => {
    return (
        <div style={{position:"relative", paddingBottom:"56.25%", height:"0", overflow:"hidden"}}>
            <iframe
                title={data?.title}
                style={{width:"100%", height:"100%", position:"absolute", left:"0px", top:"0px", overflow:"hidden", border:"none"}}
                src={`https://www.dailymotion.com/embed/video/${data?.video_id}?controls=1&endscreen-enable=false`}
                allowFullScreen
            ></iframe>
        </div>
    )
}

export const RuTubeFrame = ({data}) => {
    return (
        <div style={{position:"relative", paddingBottom:"56.25%", height:"0", overflow:"hidden"}}>
            <iframe
                title={data.title}
                style={{width:"100%", height:"100%", position:"absolute", left:"0px", top:"0px", overflow:"hidden", border:"none"}}
                src={`https://rutube.ru/play/embed/`+data?.video_id}
                allowFullScreen
            ></iframe>
        </div>
    )
}

export const YouTubeFrame = ({data}) => {
    return (
        <div style={{position:"relative", paddingBottom:"56.25%", height:"0", overflow:"hidden"}}>
            <iframe
                title={data.title}
                style={{width:"100%", height:"100%", position:"absolute", left:"0px", top:"0px", overflow:"hidden", border:"none"}}
                src={`https://www.youtube-nocookie.com/embed/${data.video_id}?rel=0&controls=1&showinfo=1&modestbranding=1`}
                allow="picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    )
}